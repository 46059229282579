import { LRUCache } from 'lru-cache'
import { hash as ohash } from 'ohash'
import { maxContentSize } from '~/constants'
import type { Content, ContentType, PageResult } from '~/types'

const apiBaseUrl = 'https://content.bt.co/channels'

const defaultSizeLimit = 20

const promiseCache = new LRUCache<string, any>({
  max: 500,
  ttl: 10 * 1000 * 60, // 10 min
})

async function _requestContent(type: ContentType, id: string): Promise<string> {
  return await $fetch(`${type}/content-${id}.xml`, {
    baseURL: apiBaseUrl,
  })
}

export async function requestContent(type: ContentType, id: string): Promise<Content> {
  const hash = ohash([type, id])
  const state = useState<Content | null>(hash, () => null)

  if (state.value)
    return state.value

  if (!promiseCache.has(hash)) {
    try {
      const data = await _requestContent(type, id)

      const parser = new DOMParser()
      const doc1 = parser.parseFromString(data, 'text/xml')
      const items = doc1.querySelectorAll('Ad')
      const item = items[0]

      const title = item.querySelector('AdTitle')?.textContent || ''
      const description = item.querySelector('Description')?.textContent || ''
      const duration = item.querySelector('Duration')?.textContent || ''
      const contentId = item.querySelector('Creative')?.getAttribute('id') || ''

      // Get the content video URL from the CDATA in the first <Thumbnail>
      const thumbnail = (item.querySelector('Thumbnail')?.firstChild as Text)?.wholeText.trim() || ''

      const content: Content = {
        fetchId: id,
        id: contentId,
        title,
        description,
        duration,
        thumbnail,
        type,
      }

      state.value = content
      promiseCache.set(hash, Promise.resolve(content))
      return content
    }
    catch (e) {
      promiseCache.delete(hash)
      throw createError(e as any)
    }
  }

  return promiseCache.get(hash)!
}

export async function requestContentNoCache(type: ContentType, id: string) {
  try {
    const data = await _requestContent(type, id)

    const parser = new DOMParser()
    const doc1 = parser.parseFromString(data, 'text/xml')
    const items = doc1.querySelectorAll('Ad')
    const item = items[0]

    const title = item.querySelector('AdTitle')?.textContent || ''
    const description = item.querySelector('Description')?.textContent || ''
    const duration = item.querySelector('Duration')?.textContent || ''
    const contentId = item.querySelector('Creative')?.getAttribute('id') || ''

    // Get the content video URL from the CDATA in the first <Thumbnail>
    const thumbnail = (item.querySelector('Thumbnail')?.firstChild as Text)?.wholeText.trim() || ''

    const content: Content = {
      fetchId: id,
      id: contentId,
      title,
      description,
      duration,
      thumbnail,
      type,
    }

    return content
  }
  catch (e) {
    throw createError(e as any)
  }
}

export async function listContentById(type: ContentType, ids: number[]): Promise<PageResult<Content>> {
  let results: Content[] = []
  let total = maxContentSize
  try {
    results = await Promise.all(ids.map(id => requestContentNoCache(type, id.toString())))
  }
  catch (error) {
    results = []
    total = 0
  }

  return {
    results,
    total,
  }
}

export function listContentByPageWrapper(type: ContentType) {
  const total = initRandomIndexArray(maxContentSize)

  return (page: number, size = defaultSizeLimit) => {
    const ids = total.slice((page - 1) * size, page * size)
    return listContentById(type, ids)
  }
}
