<script setup lang="ts">
import type { Content } from '~/types'

const props = defineProps<{
  item: Content
}>()

const path = `/watch/${props.item.type}/${props.item.fetchId}`

function handleClick(e: MouseEvent) {
  e.preventDefault()
  navigateTo(path)
}
</script>

<template>
  <a :href="path" :title="item.title" @click="handleClick">
    <div
      class="aspect-[5/3] p-1 bg-green-50/10 transition duration-500 hover:scale-105 relative"
    >
      <NuxtImg
        v-if="item.thumbnail"
        format="webp"
        :src="item.thumbnail.replace(/\.jpg/, '_300x.jpg')"
        :alt="item.title"
        class="object-cover w-full h-full"
      />
      <div class="absolute inset-0 flex w-full h-full text-white transition opacity-20 hover:opacity-100">
        <Icon name="i-ph-play" class="m-auto" size="2rem" />
      </div>
    </div>
    <h3 class="mt-1 text-base truncate">
      <slot name="title" />
    </h3>
    <div class="flex flex-col mt-1 mb-3 text-xs font-normal lg:flex-row lg:gap-2.5">
      <slot name="metaInfo" />
    </div>
    <p class="text-sm font-extralight line-clamp-2 lg:line-clamp-3">
      <slot name="description" />
    </p>
  </a>
</template>
