
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "6d0f95e6-1f9e-4d34-9035-d64f1175cc72"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/prow/go/src/github.com/bittorrent/ads-content-website/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
